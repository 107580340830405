import { defineType } from '@sanity/types';

import { Document } from '../_types';

export type FragmentFlowsType = Document & {
	_type: 'FragmentFlows';

	// __ref prevents automatic dereferencing

	registrationPrefaceFlow: { __ref: string };
	checkoutPrefaceFlow: { __ref: string };
	welcomeFlow: { __ref: string };
	checkinFlow: { __ref: string };
};

export default defineType({
	name: 'FragmentFlows',
	type: 'document',
	fields: [
		{
			name: 'registrationPrefaceFlow',
			type: 'reference',
			validation: (r) => r.required(),
			to: [{ type: 'Flow' }],
		},
		{
			name: 'checkoutPrefaceFlow',
			type: 'reference',
			validation: (r) => r.required(),
			to: [{ type: 'Flow' }],
		},
		{
			name: 'welcomeFlow',
			type: 'reference',
			validation: (r) => r.required(),
			to: [{ type: 'Flow' }],
		},
		{
			name: 'checkinFlow',
			type: 'reference',
			validation: (r) => r.required(),
			to: [{ type: 'Flow' }],
		},
	],
});
