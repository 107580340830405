import { HiOutlineDocumentText } from '@react-icons/all-files/hi/HiOutlineDocumentText';
import { getExtension } from '@sanity/asset-utils';
import { SanityImageObject } from '@sanity/image-url/lib/types/types';
import { defineType, Image } from '@sanity/types';

import { FlowBaseStepType } from './baseTypes';

export type FlowItoLoadingAnimationStepType = FlowBaseStepType & {
	_type: 'FlowItoLoadingAnimationStep';
	hideProgressBar?: boolean;
	animationSpeedMultiplier?: number;
	items: LoadingItem[];
	nextCtaText: string;
};

type LoadingItem = {
	text: string;
	image: Image;
};

export default defineType({
	name: 'FlowItoLoadingAnimationStep',
	type: 'document',
	icon: HiOutlineDocumentText,
	fields: [
		{
			name: 'hideProgressBar',
			type: 'boolean',
			description: 'If true, the progress bar will not be shown on the screen.',
			initialValue: false,
		},
		{
			name: 'animationSpeedMultiplier',
			type: 'number',
			description: 'Multiplayer for the animation speed.',
			initialValue: 1,
			validation: (r) => r.positive().greaterThan(0),
		},
		{
			name: 'items',
			type: 'array',
			validation: (r) => r.required(),
			of: [
				{
					name: 'loadingItem',
					type: 'object',
					fields: [
						{
							name: 'text',
							type: 'string',
							validation: (r) => r.required(),
						},
						{
							name: 'image',
							type: 'image',
							validation: (r) => {
								return r.required().custom<SanityImageObject>((value) => {
									if (value && getExtension(value.asset._ref) !== 'svg') {
										return 'Only SVG images are allowed';
									}
									return true;
								});
							},
						},
					],
					preview: {
						select: {
							title: 'text',
						},
					},
				},
			],
		},
		{
			name: 'nextCtaText',
			type: 'string',
			validation: (r) => r.required(),
		},
	],
});
