import { defineType } from '@sanity/types';

import { BlockContentWrapperType } from '../block/BlockContentWrapper';
import { FlowBaseStepType } from './baseTypes';

export type FlowCheckInQuestionsStepType = FlowBaseStepType & {
	_type: 'FlowCheckInQuestionsStep';
	checkInQuestions: {
		content: BlockContentWrapperType;
		positive: string;
		negative: string;
	}[];
	nextCtaText: string;
};

export default defineType({
	name: 'FlowCheckInQuestionsStep',
	type: 'document',
	fields: [
		{
			name: 'checkInQuestions',
			type: 'array',
			title: 'Check-in questions',
			description:
				'This is data which will be used to display the check-in questions with dynamic header strategy counter',
			validation: (r) => r.required().length(2),
			of: [
				{
					type: 'object',
					fields: [
						{
							name: 'content',
							type: 'BlockContent',
							validation: (r) => r.required(),
						},
						{
							name: 'positive',
							type: 'string',
							validation: (r) => r.required(),
						},
						{
							name: 'negative',
							type: 'string',
							validation: (r) => r.required(),
						},
					],
				},
			],
		},
		{
			name: 'nextCtaText',
			type: 'string',
			initialValue: 'Next',
			validation: (r) => r.required(),
		},
	],
});
