import { FaRobot } from '@react-icons/all-files/fa6/FaRobot';
import { defineField, defineType } from '@sanity/types';

import { Document } from './_types';
import { ksuidIdField } from './_utils';
import { ItoLLMConfigType } from './ItoLLMConfig';

const llmConfigField = defineField({
	name: 'llmConfig',
	type: 'reference',
	validation: (r) => r.required(),
	to: [{ type: 'ItoLLMConfig' }],
});

export type ItoPlanAssistantConfigStub = {
	id: string;
	planConfig: {
		llmConfig: ItoLLMConfigType;
	};
};

export type ItoPlanConfigType = Document & {
	_type: 'ItoPlanConfig';
	id: string;
	graph: string;
	nodeConfig: {
		plan: {
			llmConfig: ItoLLMConfigType;
		};
	};
};

export default defineType({
	name: 'ItoPlanConfig',
	type: 'document',
	icon: FaRobot,
	fields: [
		ksuidIdField('itoplanconf', true),
		{
			name: 'graph',
			type: 'string',
			description: 'The graph to use for this configuration',
			validation: (r) => r.required(),
			initialValue: 'plans',
			readOnly: true,
		},
		{
			name: 'nodeConfig',
			type: 'object',
			description: 'Configuration for individual nodes',
			validation: (r) => r.required(),
			fields: [
				{
					name: 'plan',
					type: 'object',
					validation: (r) => r.required(),
					fields: [llmConfigField],
				},
				{
					name: 'planLength',
					type: 'number',
					description: 'The number of days in the plan',
					validation: (r) => r.required(),
					initialValue: 7,
				},
			],
		},
	],
	preview: {
		select: {
			id: 'id',
			graph: 'graph',
		},
		prepare({ id, graph }) {
			return {
				title: graph,
				subtitle: `${graph} (${id})`,
			};
		},
	},
});
