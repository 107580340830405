import {
	defineField,
	PreviewConfig,
	Rule,
	ValidationContext,
} from '@sanity/types';
import ksuid from '@wearemojo/ksuid';

import { Document } from './_types';

export const slugify = (value: string) =>
	value
		.toLowerCase()
		.replace(/[^a-z\d\s_-]/g, '')
		.trim()
		.replace(/[\s_-]+/g, '-')
		.slice(0, 50);

export const previewNamedBlockContent = (
	nameField = 'name',
	contentField = 'content',
): PreviewConfig => {
	return {
		select: {
			name: nameField,
			content: contentField,
		},
		prepare({ name, content }) {
			return {
				title: Array.isArray(name)
					? name?.[0]?.children?.[0]?.text
					: String(name),
				subtitle: content?.[0]?.children?.[0]?.text,
			};
		},
	};
};

export const previewBlockContent = (fieldName = 'content'): PreviewConfig => {
	return {
		select: {
			content: fieldName,
		},
		prepare({ content }) {
			return {
				title:
					content?.[0]?.children?.[0]?.text ||
					content?.[0]?.content?.content?.[0]?.children?.[0]?.text,
				subtitle:
					content?.[0]?.children?.[1]?.text ||
					content?.[1]?.children?.[0]?.text,
			};
		},
	};
};

export const validateSnakeCase = (r: Rule) =>
	r.custom<string>((value) => {
		const snakeCaseRegex = /^[a-z0-9_]+$/;
		return snakeCaseRegex.test(value) || `Must be snake_case (e.g. "my_field")`;
	});

const isUniqueId = async (mojoId: string, context: ValidationContext) => {
	if (!context.document) return;

	const client = context.getClient({ apiVersion: '2022-02-09' });
	const sanityId = context.document._id.replace(/^drafts\./, '');

	return await client.fetch<boolean>(
		`
			!defined(*[
				!(_id in [$sanityId, $sanityDraftId])
				&& id == $mojoId
			][0]._id)
		`,
		{
			sanityId,
			sanityDraftId: `drafts.${sanityId}`,
			mojoId,
		},
	);
};

export const ksuidIdField = (resource: string, checkUniqueness = false) =>
	defineField({
		name: 'id',
		type: 'string',
		validation: (r) =>
			r
				.required()
				.custom((value) => {
					if (!value) return true;
					try {
						const id = ksuid.parse(value);
						if (id.resource === resource) return true;
						return `Must be a ${resource} ID`;
					} catch {}
					return 'Invalid ID';
				})
				.custom(async (value, context) => {
					if (!checkUniqueness || !value) return true;
					if (await isUniqueId(value, context)) return true;
					return 'ID must be unique';
				}),
		initialValue: () => ksuid.generate(resource).toString(),
		readOnly: (c) =>
			(c.document as Document | undefined)?.std_published ?? false,
	});

export const validateDynamicContent = <
	T extends {
		choices?: {
			options: { key: string }[];
		}[];
		dynamicContent: { key?: string }[];
	} = never,
>(
	value: T['dynamicContent'],
	context: ValidationContext,
) => {
	if (!value?.length) return true;

	const dynamicKeys = value.map((content) => content.key ?? '');
	const parent = context.parent as T;
	const allOptionKeys = parent.choices?.map(({ options }) =>
		options.map(({ key }) => key),
	);
	const allDynamicKeys = generateAllPermutations(allOptionKeys ?? []);

	// Validate that all options are unique
	if (dynamicKeys.length !== new Set(dynamicKeys).size) {
		return 'Dynamic content keys must be unique';
	}

	// Validate that all options are valid
	const invalidKeys = dynamicKeys.filter(
		(option: string) => !allDynamicKeys.includes(option),
	);
	if (invalidKeys.length) return `Invalid options: ${invalidKeys.join(', ')}`;

	// Validate that all options are present
	const missingKeys = allDynamicKeys.filter(
		(option: string) => !dynamicKeys.includes(option),
	);
	if (missingKeys.length) return `Missing options: ${missingKeys.join(', ')}`;

	return true;
};

/**
 * Generates all permutations of the given array of strings.
 *
 * @param arr - An array of arrays of strings. Each inner array represents a set of options.
 * @param separator - A string used to join the elements of the permutations.
 *
 * @returns An array of strings, each string being a permutation of the input, with elements joined by the separator.
 *
 * @example
 * // returns ['1/a', '1/b', '2/a', '2/b']
 * generateAllPermutations([['1', '2'], ['a', 'b']], '/');
 */
export const generateAllPermutations = (
	arr: string[][],
	separator: string = '/',
) => {
	// The function starts with an empty prefix and for each option in the current set,
	// it adds the option to the prefix and calls itself with the next set of options.
	// When it has processed all sets of options, it adds the current permutation (the joined prefix) to the result set.
	const result = new Set<string>();

	function permuteRecursive(index: number, prefix: string[]): void {
		if (index === arr.length) {
			result.add(prefix.join(separator));
			return;
		}

		const options = arr[index];

		if (!options) {
			throw new Error(`No options found for index ${index}, ${arr}`);
		}

		for (const option of options) {
			prefix.push(option);
			permuteRecursive(index + 1, prefix);
			prefix.pop();
		}
	}

	permuteRecursive(0, []);

	return Array.from(result);
};
