import { HiOutlineDocumentText } from '@react-icons/all-files/hi/HiOutlineDocumentText';
import { defineType } from '@sanity/types';

import { previewBlockContent } from '../_utils';
import { BlockContentType } from '../block/_types';
import { FlowBaseStepType } from './baseTypes';

export type FlowItoPlanStepType = FlowBaseStepType & {
	_type: 'FlowItoPlanStep';
	content: BlockContentType;
};

export default defineType({
	name: 'FlowItoPlanStep',
	type: 'document',
	icon: HiOutlineDocumentText,
	fields: [
		{
			name: 'title',
			type: 'InlineContent',
			validation: (r) => r.length(1),
		},
		{
			name: 'content',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
		{
			name: 'nextCtaText',
			type: 'string',
			initialValue: 'Next',
			validation: (r) => r.required(),
		},
	],
	preview: previewBlockContent(),
});
