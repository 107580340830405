import { defineType } from '@sanity/types';

import { Document } from '../_types';
import { BlockContentWrapperType } from '../block/BlockContentWrapper';

export type FragmentRoutineType = Document & {
	_type: 'FragmentRoutine';
	title: string;
	goalText: string;
	checkInText: string;
	bottomSheet: {
		title: string;
		content: BlockContentWrapperType;
		goalTitle: string;
		areasOfImprovementTitle: string;
	};
};

export default defineType({
	name: 'FragmentRoutine',
	type: 'document',
	fields: [
		{
			name: 'title',
			type: 'string',
			validation: (r) => r.required(),
			initialValue: 'Your routine',
		},
		{
			name: 'goalText',
			type: 'string',
			validation: (r) => r.required(),
			initialValue: 'Your goal',
		},
		{
			name: 'checkInText',
			type: 'string',
			validation: (r) => r.required(),
			initialValue: 'Check in and start a new routine',
		},
		{
			name: 'bottomSheet',
			type: 'object',
			validation: (r) => r.required(),
			fields: [
				{
					name: 'title',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'content',
					type: 'reference',
					to: [{ type: 'BlockContentWrapper' }],
					validation: (r) => r.required(),
				},
				{
					name: 'goalTitle',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'areasOfImprovementTitle',
					type: 'string',
					validation: (r) => r.required(),
				},
			],
		},
	],
});
