import { defineType } from '@sanity/types';

import { validateSnakeCase } from '../_utils';
import { BlockContentWrapperType } from '../block/BlockContentWrapper';
import { InlineContentWrapperType } from '../block/InlineContentWrapper';
import { ItoAssistantConfigStub } from '../ItoAssistantConfig';
import { PollType } from '../Poll';
import { FlowBaseStepType } from './baseTypes';

export type FlowPlanStrategiesStepType = FlowBaseStepType & {
	_type: 'FlowPlanStrategiesStep';
	strategies: {
		content: BlockContentWrapperType;
		listTitle: InlineContentWrapperType;
		customizeCtaText: string;
	};
	strategiesSelection: {
		title: InlineContentWrapperType;
		content: BlockContentWrapperType;
		determiningPoll: PollType;
		strategyPolls: StrategyPoll[];
		saveCtaText: string;
		cancelCtaText: string;
	};
	assistantConfig: ItoAssistantConfigStub;
};

type StrategyPoll = {
	determiningPollOptionKey: string;
	poll: PollType;
};

export default defineType({
	name: 'FlowPlanStrategiesStep',
	type: 'document',
	fields: [
		{
			name: 'strategies',
			type: 'object',
			validation: (r) => r.required(),
			fields: [
				{
					name: 'content',
					type: 'reference',
					to: [{ type: 'BlockContentWrapper' }],
					validation: (r) => r.required(),
				},
				{
					name: 'listTitle',
					type: 'reference',
					to: [{ type: 'InlineContentWrapper' }],
					validation: (r) => r.required(),
				},
				{
					name: 'customizeCtaText',
					type: 'string',
					validation: (r) => r.required(),
				},
			],
		},
		{
			name: 'strategiesSelection',
			type: 'object',
			validation: (r) => r.required(),
			fields: [
				{
					name: 'title',
					type: 'reference',
					to: [{ type: 'InlineContentWrapper' }],
					description: 'Title of the strategies selection step',
					validation: (r) => r.required(),
				},
				{
					name: 'content',
					type: 'reference',
					to: [{ type: 'BlockContentWrapper' }],
					description: 'Content of the strategies selection step',
					validation: (r) => r.required(),
				},
				{
					name: 'determiningPoll',
					type: 'reference',
					to: [{ type: 'Poll' }],
					description:
						'Reference to poll options for determining strategies selection',
					validation: (r) => r.required(),
				},
				{
					name: 'strategyPolls',
					type: 'array',
					description: 'Polls for strategies selection',
					validation: (r) =>
						r.custom<StrategyPoll[]>((val) => {
							const keySet = new Set<string>();

							if (!val?.length) return 'At least one strategy poll is required';

							for (const { determiningPollOptionKey } of val) {
								if (keySet.has(determiningPollOptionKey)) {
									return `Duplicate determiningPollOptionKey: ${determiningPollOptionKey}`;
								}
								keySet.add(determiningPollOptionKey);
							}
							return true;
						}),
					of: [
						{
							type: 'object',
							validation: (r) => r.required(),
							fields: [
								{
									name: 'determiningPollOptionKey',
									type: 'string',
									validation: (r) => validateSnakeCase(r).required(),
								},
								{
									name: 'poll',
									type: 'reference',
									to: [{ type: 'Poll' }],
									validation: (r) => r.required(),
								},
							],
							preview: {
								select: {
									title: 'determiningPollOptionKey',
								},
							},
						},
					],
				},
				{
					name: 'saveCtaText',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'cancelCtaText',
					type: 'string',
					validation: (r) => r.required(),
				},
			],
		},
		{
			name: 'assistantConfig',
			type: 'reference',
			description: 'The assistant configuration to use create the plan',
			to: [{ type: 'ItoAssistantConfig' }],
			validation: (r) => r.required(),
		},
		{
			name: 'nextCtaText',
			type: 'string',
			validation: (r) => r.required(),
		},
	],
	preview: {
		select: {
			title: 'strategies.listTitle',
		},
		prepare: ({ title }) => {
			return {
				title: `${title._ref}`,
			};
		},
	},
});
