import { HiCalendar } from '@react-icons/all-files/hi/HiCalendar';
import { defineType } from '@sanity/types';

export type RoutineBlockType = {
	_type: 'Routine';
	variant?: 'default' | 'withFooter';
	checkInText?: string;
};

export default defineType({
	name: 'Routine',
	type: 'object',
	icon: HiCalendar,
	fields: [
		{
			name: 'variant',
			type: 'string',
			options: {
				list: [
					{ title: 'Default', value: 'default' },
					{ title: 'With Footer', value: 'withFooter' },
				],
			},
			initialValue: 'default',
		},
		{ name: 'checkInText', type: 'string' },
	],
	preview: {
		prepare() {
			return {
				title: 'Routine',
			};
		},
	},
});
