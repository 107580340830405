import { defineType } from '@sanity/types';

import { BlockContentWrapperType } from '../block/BlockContentWrapper';
import { FlowBaseStepType } from './baseTypes';

export type FlowItoNotificationsStepType = FlowBaseStepType & {
	_type: 'FlowItoNotificationsStep';
	content: BlockContentWrapperType;
	enableNotificationText: string;
};

export default defineType({
	name: 'FlowItoNotificationsStep',
	type: 'document',
	fields: [
		{
			name: 'content',
			type: 'reference',
			validation: (r) => r.required(),
			to: [{ type: 'BlockContentWrapper' }],
		},
		{
			name: 'enableNotificationText',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'nextCtaText',
			type: 'string',
			initialValue: 'Get started',
			validation: (r) => r.required(),
		},
	],
});
